import Heading from "./Helpers/Heading"
import { useQuery } from 'react-query'
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from "../hooks/useAuth"
import ErrorAlert from "./Helpers/ErrorAlert"
import Loader from "./Helpers/Loader"


function Profile() {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()

  const getUserByUserId = async () => {
    try {
      const userId = auth._id
      const response = await axiosPrivate.get(`/users/${userId}`)
      return response.data
    } catch (err) {
      console.error('Error while fetching exams', err)
      throw new Error('There was an error while fetching exams')
    }
  }

  const { data, isLoading, isError } = useQuery('user-details', getUserByUserId)
  return (
    <>
      <div className="flex flex-col mt-10 items-center">
        <Heading heading="Profile" />
        <div className="min-h-screen  items-stretch flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            <div className="mt-6 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2">
                {data && <>
                  <div key={data._id} className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{data.name}</dd>
                  </div>
                  <div key={data.address} className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Address</dt>
                    <dd className="mt-1 text-sm text-gray-900">{data.address}</dd>
                  </div>
                  <div key="role" className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Role</dt>
                    <dd className="mt-1 text-sm text-gray-900">User</dd>
                  </div>
                </>}
                {isError && <ErrorAlert message="There was some error fetching user details" />}
                {isLoading && <Loader message="Loading user details" />}
              </dl>
            </div>
          </main>
        </div>

      </div>
    </>
  )
}

export default Profile