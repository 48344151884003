import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { axiosPrivate } from '../api/axios'
import useAuth from '../hooks/useAuth'
import AdminTable from './Helpers/AdminTable'
import ErrorAlert from './Helpers/ErrorAlert'
import Heading from './Helpers/Heading'
import Loader from './Helpers/Loader'


function Admin() {
  const [seqData, setSeqData] = useState(null)
  const [err, setError] = useState(null)

  const formatSequence = (data) => {
    const seqData = data.reduce((prev, curr) => {
      const formattedUser = curr.users.map(singleUser => {
        const currPayment = singleUser.payments.filter(payment => payment.courseId === curr._id)
        singleUser.payments = currPayment
        return singleUser
      })
      const index = prev.findIndex(prevItem => {
        return prevItem.name === curr.coursedetails
      })
      if (index > -1) {
        prev[index].users = formattedUser
        //sort
        prev[index].users.sort((a, b) => {
          if (a?.payments[0]?.createdAt && b?.payments[0]?.createdAt) {
            return new Date(a?.payments[0]?.createdAt) - new Date(b?.payments[0]?.createdAt)
          } else {
            return a?.payments[0]?.createdAt - b?.payments[0]?.createdAt
          }
        })
      }
      return prev
    }, [{
      name: 'Evaluating Exam - Question Bank',
      users: [],
    },
    {
      name: 'Evaluating Exam - Preparatory Material',
      users: [],
    },
    {
      name: 'Evaluating Exam - Question Bank + Preparatory Material',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - I (MCQ) - Question Bank',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - I (MCQ) - Preparatory Material',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - I (MCQ) - Question Bank + Preparatory Material',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - II (OSCE) - Day 1',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - II (OSCE) - Day 2',
      users: [],
    },
    {
      name: 'Qualifying Exam Part - II (OSCE) - Day 3',
      users: [],
    }])
    return seqData;
  }
  const getAllUsers = async () => {
    try {
      setError(null)
      const response = await axiosPrivate.get(`/users/adminusers`)
      return response
    } catch (error) {
      console.error("Error while fetching the users for admin 🛑", error)
      setError('There was an error while fetching students for admin view')
      throw new Error('Error while fetching the users for admin')
    }
  }
  const { data, isLoading, isError } = useQuery('allStudents', getAllUsers)
  useEffect(() => {
    if (!isLoading && !isError && data.data.length) {
      const sequencedData = formatSequence(data.data)
      setSeqData(sequencedData)
    }
  }, [data])

  const uploadExam = async () => {
    try {
      const response = await axiosPrivate.post('/admin/uploadexam')
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }


  return (
    <>
      <div className='flex flex-1 flex-col p-8 min-h-screen'>
        <Heading heading={'Admin section'} />
        <h1 className='my-2'>Students enrolled</h1>
        {isLoading && <Loader message="Loading students details" />}
        {!isLoading && isError && <ErrorAlert message="There was an error while fetching students for the admin view. Please sign out and re-login." />}
        {/* {!isError && !isLoading && data && <AdminTable data={data.data[1].users} />} */}
        {!isError && !isLoading && seqData && seqData.map((singleData, index) => (
          <>
            {singleData.users.length > 0 && (
              <span key={index}>
                <h1 className='text-black font-bold my-2'>{singleData.name}</h1>
                <AdminTable data={singleData.users} />
                {/* Divider */}
                <div className="relative my-8">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-800" />
                  </div>
                  <div className="relative flex justify-center">
                  </div>
                </div>
              </span>
            )}
          </>
        ))}
      </div>
      {/* <button
        className='bg-purple-800 p-2 text-white my-4'
        onClick={() => uploadExam()}
      >Upload Exam</button> */}
    </>
  )
}

export default Admin