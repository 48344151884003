import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  getSortedRowModel
} from "@tanstack/react-table";
import { useState } from "react";
import { format } from 'date-fns'

const columns = [
  {
    accessorKey: "#",
    cell: (info) => ((info?.row?.index + 1) || '#')
  },
  {
    accessorKey: "name",
    cell: (info) => info.getValue()
  },
  {
    accessorKey: "email",
    cell: (info) => info.getValue()
  },
  {
    accessorKey: "address",
    cell: (info) => info.renderValue()
  },
  {
    accessorKey: "phone",
    cell: (info) => {
      return info.getValue() || 'NA'
    }
  },
  // {
  //   accessorKey: "payments",
  //   cell: (info) => {
  //     return (info?.row?.original?.payments[0]?.amount || 'NA')
  //   },
  // },
  {
    accessorKey: "Payment Date",
    accessorFn: (info) => {
      return (info?.payments[0]?.createdAt ? format(new Date(info.payments[0].createdAt), 'dd/MMM/yyyy') : '-')
    }
  },
];

function AdminTable({ data }) {
  const [sorting, setSorting] = useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugAll: false
  });

  return (
    <>
      <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">

            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <>
                    <th scope="col" className="hidden capitalize px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell" key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  </>
                ))}
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:hidden">
                  Details
                </th>
              </tr>
            ))}
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {table.getRowModel().rows.map((row) => (
              <>
                <tr className="hidden lg:table-row" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="px-3 py-4 text-sm text-gray-800 table-cell" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
                <tr className="lg:hidden">
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    <dl className="font-normal lg:hidden">
                      {row.getVisibleCells().map((cell) => (
                        <dd className="mt-1 truncate text-gray-700">{flexRender(cell.column.columnDef.cell, cell.getContext())}</dd>
                      ))}
                    </dl>
                  </td>
                </tr>
              </>
            ))}
          </tbody>


        </table>
      </div>
    </>
  )
}

export default AdminTable