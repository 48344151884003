import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../Logo/logopurple.svg';
import { Mutation, useMutation } from "react-query";
import ErrorAlert from "./Helpers/ErrorAlert";


const LOGIN_URL = '/auth';
export default function Login() {
  const [errMsg, setErrMsg] = useState('');
  const { setAuth, persist } = useAuth()
  const { register, handleSubmit } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/portal/dashboard";
  const loginMutateFn = async (payload) => {
    const { email, password } = payload
    return await axios.post(LOGIN_URL,
      JSON.stringify({ email, password }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      })
  }

  const mutation = useMutation(loginMutateFn, {
    onSuccess: (data) => {
      const accessToken = data?.data?.accessToken;
      const roles = data?.data?.roles;
      const name = data?.data?.name
      const emailAddress = data?.data?.email
      const address = data?.data?.address
      const courseEnrolled = data?.data?.courseEnrolled
      const payments = data?.data?.payments
      const _id = data?.data?._id
      setAuth({ _id, name, emailAddress, address, courseEnrolled, payments, roles, accessToken });
      navigate(from, { replace: true });
    },
    onError: (err) => {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Email or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Either username or password is wrong.');
      } else {
        setErrMsg('Login Failed');
      }
    },
    retry: 2
  })

  const onSubmit = async (data) => {
    if (!data.email || !data.password) {
      return setErrMsg('Missing Email or Password');
    }
    mutation.mutate(data)
  }

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist])

  return (
    <>
      <div className="min-h-full bg-purple-100 w-96 flex flex-col justify-center m-auto py-4 sm:px-4 lg:px-4">
        <div className="h-32 w-32 mx-auto  overflow-hidden">
          <img src={logo} alt="PharmXellence Logo" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Log in</h2>
        <div className="mt-8">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register("email")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    {...register("password")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="text-sm">
                <Link to="/forgot-password" className="font-medium text-purple-600 hover:text-purple-500">
                  Forgot your password?
                </Link>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-800 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  {mutation.isLoading ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> : 'Login'}
                </button>
              </div>
              {errMsg && <ErrorAlert message={errMsg} />}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
