import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import ErrorAlert from './ErrorAlert';
import Loader from './Loader';


function ResultsTable() {
  //Context
  const { auth } = useAuth();
  const axios = useAxiosPrivate()

  //RQ stuff
  const getResults = async () => {
    try {
      const response = await axios.get(`/results/${auth._id}`)
      return response
    } catch (err) {
      console.error("err while fetching the result 🛑", err)
      throw new Error('Err while fetching the result')
    }
  }
  const { data, isLoading, isError } = useQuery('result', getResults)

  return (
    <>
      {isLoading && <Loader message="Loading results" />}
      {isError && <ErrorAlert message="There was some error loading your result" />}
      {data?.data?.data?.length > 0 && <><div className="shadow sm:hidden">
        <ul role="list" className="mt-2 divide-y divide-gray-200 max-h-[400px] overflow-y-auto shadow sm:hidden">
          {data.data.data.length > 0 && data.data.data.map((result) => (
            <li key={result._id}>
              <div className="block px-4 py-4 bg-white hover:bg-gray-50">
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{result.examId.examName}</span>
                      <span>
                        <span className="text-gray-900 font-medium">{result.obtainedMarks} / {result.maxMarks}</span>{' '}
                      </span>
                      <time>{format(new Date(result.updatedAt), 'dd-MMM-yyyy')}</time>
                    </span>
                  </span>
                  <Link to={`/portal/results/${result._id}/1`}>
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

        <div className="hidden sm:block mt-4">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto shadow max-h-[600px] overflow-y-auto sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Exam Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Score
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Percentage
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Review
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-[100px] overflow-y-scroll">
                    {data.data.data.length > 0 && data.data.data.map((result) => (
                      <tr key={result._id} className="bg-white">
                        <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <div className="flex">
                            <div href={result.href} className="group inline-flex space-x-2 truncate text-sm">
                              <Link to={`/portal/results/${result._id}/1`} className="text-gray-500 truncate group-hover:text-gray-900 ">
                                {result.examId.examName}
                              </Link>
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 pointer-events-none">
                          <span className="text-gray-900 font-medium">{result.obtainedMarks} / {result.maxMarks} </span>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 pointer-events-none">
                          <span className="text-gray-900 font-medium">{(result.obtainedMarks / result.maxMarks * 100).toFixed(1)} % </span>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500 pointer-events-none">
                          <time>{format(new Date(result.updatedAt), 'dd-MMM-yyyy')}</time>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm">
                          <Link to={`/portal/results/${result._id}/1`}>Review</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      }
      {!data?.data?.data?.length && <div> No results to display. Please complete one exam.</div>}


    </>
  )
}

export default ResultsTable