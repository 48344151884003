import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
const { format } = require('date-fns');

function PaymentSuccess() {
  const axios = useAxiosPrivate()
  let [searchParams, setSearchParams] = useSearchParams();
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [refreshError, setRefreshError] = useState(false)
  const { auth, setAuth } = useAuth()

  const sess = searchParams.get("session_id");

  const navigate = useNavigate();

  const extractRequiredInfo = (session) => {
    const userId = auth._id
    const courseName = session?.line_items?.data[0]?.description
    const amount = session?.line_items?.data[0]?.amount_total / 100
    const date = format(new Date(), 'dd-MMM-yyyy')
    const email = session?.customer_email
    const name = session?.metadata?.customerName
    const priceId = session?.line_items.data[0]?.price.id
    const session_id = session?.id
    const address = session?.metadata?.customerAddress
    const paymentData = {
      name,
      email,
      courseName,
      amount,
      date,
      address,
    }
    const requiredData = {
      ...paymentData,
      userId,
      priceId,
      session_id
    }
    setPaymentDetails(paymentData)
    return requiredData
  }

  const getCustomerInfo = async () => {
    try {
      if (!sess) {
        setRefreshError(true)
        return
      }
      // TODO: Here we can add some logic to include double checking...
      const response = await axios.post('/stripe/customer-info', { session_id: sess })
      const requiredData = extractRequiredInfo(response.data.sessionData.session)
      return requiredData
    } catch (error) {
      console.error("There was some error occurred while extracting the required info.", error)

    }
  }


  const confirmPaymentDetails = async () => {
    try {
      toast.info("Payment details verification will start in 15 second! Please wait...", { autoClose: 15000 });
      await new Promise(resolve => setTimeout(resolve, 15000));
      toast.info("Verification started...", { theme: "colored" })
      const response = await axios.post(`/stripe-free/validatePaymentDetails`, { session_id: sess })
      if (response.status === 200) {
        toast.success("Verification completed! Thank you", { theme: "colored" })
      } else {
        toast.error("There was an error while verifying the payment details. Contact sushant.yt@gmail.com for more info", { autoClose: false, theme: "colored" })
      }
      return response
    } catch (err) {
      console.log('err on validation :', err)
      toast.error("There was an error while verifying the payment details. Contact sushant.yt@gmail.com for more info", { autoClose: false, theme: "colored" })
    }
  }


  useEffect(() => {
    if (sess) {
      getCustomerInfo()
      confirmPaymentDetails(sess)
      navigate({
        replace: true
      })
    } else {
      setRefreshError(true)
    }
  }, [])

  return (
    <>
      {!refreshError && <div className='min-h-screen p-4 flex flex-col'>
        <ToastContainer />
        <h1 className='text-center mt-4 font-bold text-xl text-purple-600'>Thank you for purchasing the course</h1>
        <h2 className='text-center mt-2 font-semibold leading-5'>Order summary</h2>
        <div className='p-4 max-w-xl items-center self-center'>
          {paymentDetails && <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            {Object.keys(paymentDetails).map((key) => (
              <div key={key} className="py-3 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">{key}</dt>
                <dd className="text-gray-900 ml-60">{paymentDetails[key]}</dd>
              </div>
            ))}
          </dl>}
          {!paymentDetails && <p>Loading...</p>}
        </div>
        <p className="text-center text-blue-700 text-sm font-semibold">You will receive an email confirmation shortly</p>
        <div className='flex items-center mt-4 self-center'>
          <Link to='/portal/dashboard' className="px-4 py-2 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Go to Dashboard</Link>
        </div>
        <div className='max-w-xl self-center mt-2 space-y-4'>

        </div>
      </div>}
      {refreshError && <div className='min-h-screen p-4 flex flex-col'>It seems the browser is refreshed. Please navigate to the dashboard</div>}
    </>
  )
}

export default PaymentSuccess
