import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  try {
    const refresh = async () => {
      const response = await axios.get('/refresh', {
        withCredentials: true
      });
      setAuth(prev => {
        return {
          ...prev,
          _id: response.data._id,
          roles: response.data.roles,
          accessToken: response.data.accessToken,
          name: response?.data?.name,
          emailAddress: response?.data?.email,
          address: response?.data?.address,
          courseEnrolled: response?.data?.courseEnrolled,
          payments: response?.data?.payments,
        }
      });
      return response.data.accessToken;
    }
    return refresh;
  } catch (err) {
    console.log("Error on refresh token", err)
  }

};

export default useRefreshToken;
