import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from '../api/axios';
import logo from '../Logo/logopurple.svg'
import ErrorAlert from './Helpers/ErrorAlert';
import { useMutation } from 'react-query';
import SuccessAlert from './Helpers/SuccessAlert';
import { Link } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'

function ForgotPassword() {
  const { register, handleSubmit } = useForm();
  const [noUserError, setNoUserError] = useState(false)
  const forgotPasswordMutateFn = async (email) => {
    return await axios.post('/forgot_password', { email })
  }

  const mutation = useMutation(forgotPasswordMutateFn, {
    onError: (err) => {
      if (err.response.status === 401) {
        setNoUserError(true)
      }
    }
  })

  const onSubmit = async (data) => {
    try {
      mutation.reset()
      setNoUserError(false)
      const { email } = data;
      mutation.mutate(email)
    } catch (error) {
      console.log('error:', error.response)
    }
  }
  return (
    <>
      <div className="min-h-full bg-purple-100 w-[30rem] flex flex-col justify-center m-auto py-4 sm:px-4 lg:px-4">
        <div className="mx-auto  overflow-hidden space-y-6">
          <div className="h-32 w-32 mx-auto  overflow-hidden">
            <img src={logo} alt="PharmXellence Logo" />
          </div>
          <h2 className="mt-6 text-center text-xl font-semibold text-gray-900">Retrieve password</h2>
          <p className='font-normal text-md'>Please enter your email to get the link to reset the password.</p>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  {...register("email")}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-800 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                {mutation.isLoading ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> : 'Reset'}
              </button>
            </div>
          </form>
          <div className='flex flex-col space-y-4'>
            {mutation.isError && !noUserError && <ErrorAlert message="There was an error while sending the password reset email." />}
            {noUserError && <ErrorAlert message="No user with this email found." />}
            {mutation.isSuccess && <SuccessAlert message="Password reset email sent successfully!" />}
            {mutation.isSuccess && <Link className='mt-4 text-purple-900 flex items-center space-x-2' to="/">
              <span>Navigate to Portal</span>
              <ArrowNarrowRightIcon className="h-4 w-4" />
            </Link>}

          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword