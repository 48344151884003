import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import AnswerPicker from './Helpers/AnswerPicker';
import ErrorAlert from './Helpers/ErrorAlert';
import QuestionPicker from './Helpers/QuestionPicker';


function ReviewExam() {
  //Router details
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const axios = useAxiosPrivate()

  const getExamResult = async () => {
    try {
      const examResultResponse = await axios.get(`/results/exam-results/${params.resultId}`)
      return examResultResponse
    } catch (err) {
      console.error("Error while getting exam result", err)
      throw new Error("Error while fetching the exam result")
    }
  }

  const goToPrevious = () => {
    const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
    navigate(`${futureLocationPath}/${params.questionId - 1}`)
  }

  const goToNext = () => {
    const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
    navigate(`${futureLocationPath}/${Number(params.questionId) + 1}`)
  }

  const { data, isLoading, isError, error } = useQuery('exam-result', getExamResult)

  const answers = data?.data?.response?.answers || []
  return (
    <div className='min-h-screen'>
      {isLoading && <div>Loading results...</div>}
      {isError && <ErrorAlert message="Error occurred while fetching the results" />}
      {!isError && answers.length > 0 && <div>
        <div className='flex flex-col flex-1 p-2 overflow-hidden lg:flex-row'>
          <main className='p-2 lg:w-2/3'>
            <div className='mt-4 min-h-[300px]'>
              <span className='flex'>
                <h1 className='font-bold mr-2'>Q:{params.questionId}</h1>
                <h1 dangerouslySetInnerHTML={{ __html: answers[params.questionId - 1].questionId.question }}></h1>
              </span>
              <ul>
                <li>
                  <fieldset className="mt-4">
                    <div className="space-y-4">
                      <div key="optionA" className="flex items-center">
                        <input
                          id="optionA"
                          name="option"
                          type="radio"
                          className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                          value={answers[params.questionId - 1].questionId.optionA}
                          disabled
                          checked={answers[params.questionId - 1].answer === answers[params.questionId - 1].questionId.optionA}
                        />
                        <label htmlFor="optionA" className={`ml-3 block text-sm font-medium text-gray-700 ${answers[params.questionId - 1].questionId.answer === answers[params.questionId - 1].questionId.optionA ?
                          'text-green-600' :
                          answers[params.questionId - 1].answer === null ?
                            'text-gray-600' :
                            ''}`} >
                          {answers[params.questionId - 1].questionId.optionA}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </li>
                <li>
                  <fieldset className="mt-4">
                    <div className="space-y-4">
                      <div key="optionB" className="flex items-center">
                        <input
                          id="optionB"
                          name="option"
                          type="radio"
                          className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                          value={answers[params.questionId - 1].questionIdoptionB}
                          disabled
                          checked={answers[params.questionId - 1].answer === answers[params.questionId - 1].questionId.optionB}
                        />
                        <label htmlFor="optionB" className={`ml-3 block text-sm font-medium text-gray-700 ${answers[params.questionId - 1].questionId.answer === answers[params.questionId - 1].questionId.optionB ?
                          'text-green-600' :
                          answers[params.questionId - 1].answer === null ?
                            'text-gray-600' :
                            ''}`} >
                          {answers[params.questionId - 1].questionId.optionB}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </li>
                <li>
                  <fieldset className="mt-4">
                    <div className="space-y-4">
                      <div key="optionC" className="flex items-center">
                        <input
                          id="optionC"
                          name="option"
                          type="radio"
                          className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                          value={answers[params.questionId - 1].questionIdoptionC}
                          disabled
                          checked={answers[params.questionId - 1].answer === answers[params.questionId - 1].questionId.optionC}
                        />
                        <label htmlFor="optionC" className={`ml-3 block text-sm font-medium text-gray-700 ${answers[params.questionId - 1].questionId.answer === answers[params.questionId - 1].questionId.optionC ?
                          'text-green-600' :
                          answers[params.questionId - 1].answer === null ?
                            'text-gray-600' :
                            ''}`} >
                          {answers[params.questionId - 1].questionId.optionC}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </li>
                <li>
                  <fieldset className="mt-4">
                    <div className="space-y-4">
                      <div key="optionD" className="flex items-center">
                        <input
                          id="optionD"
                          name="option"
                          type="radio"
                          className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                          value={answers[params.questionId - 1].questionId.optionD}
                          disabled
                          checked={answers[params.questionId - 1].answer === answers[params.questionId - 1].questionId.optionD}
                        />
                        <label htmlFor="optionD" className={`ml-3 block text-sm font-medium text-gray-700 ${answers[params.questionId - 1].questionId.answer === answers[params.questionId - 1].questionId.optionD ?
                          'text-green-600' :
                          answers[params.questionId - 1].answer === null ?
                            'text-gray-600' :
                            ''}`} >
                          {answers[params.questionId - 1].questionId.optionD}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </li>
                {answers[params.questionId - 1]?.questionId.optionE && <li>
                  <fieldset className="mt-4">
                    <div className="space-y-4">
                      <div key="optionE" className="flex items-center">
                        <input
                          id="optionE"
                          name="option"
                          type="radio"
                          className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                          value={answers[params.questionId - 1].questionId.optionE}
                          disabled
                          checked={answers[params.questionId - 1].answer === answers[params.questionId - 1].questionId.optionE}
                        />
                        <label htmlFor="optionE" className={`ml-3 block text-sm font-medium text-gray-700 ${answers[params.questionId - 1].questionId.answer === answers[params.questionId - 1].questionId.optionE ?
                          'text-green-600' :
                          answers[params.questionId - 1].answer === null ?
                            'text-gray-600' :
                            ''}`} >
                          {answers[params.questionId - 1].questionId.optionE}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </li>}
                {answers[params.questionId - 1].questionId.explaination !== '.' && <li className='mt-4'>
                  <span className='font-bold'>Explanation:</span>
                  <p dangerouslySetInnerHTML={{ __html: answers[params.questionId - 1].questionId.explaination }}></p>
                </li>}
              </ul>
            </div>
            <div className='flex justify-between mt-10'>
              <div>
                <button
                  type="button"
                  disabled={Number(params.questionId) === 1}
                  className="mr-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:text-purple-300 disabled:bg-purple-100 disabled:pointer-events-none"
                  onClick={goToPrevious}
                >
                  Previous
                </button>
                <button
                  type="button"
                  disabled={Number(params.questionId) === (answers.length)}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:text-purple-300 disabled:bg-purple-100 disabled:pointer-events-none"
                  onClick={goToNext}
                >
                  Next
                </button>
              </div>
            </div>
          </main>
          <aside className="mt-10 border-t overflow-y-auto border-gray-400 p-2 lg:w-1/3 lg:mt-0 lg:border-l">
            <AnswerPicker totalAnswers={answers} />
          </aside>
        </div>
      </div>}
    </div>
  )
}

export default ReviewExam