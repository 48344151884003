import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { HomeIcon, MenuAlt2Icon, ViewGridIcon, XIcon, CurrencyDollarIcon, BookOpenIcon } from '@heroicons/react/outline'
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useExamMode from '../hooks/useExamMode'
import white_logo from '../Logo/Logo_white.svg'
import useAuth from '../hooks/useAuth'


const sidebarNavigation = [
  { name: 'Dashboard', link: '/portal/dashboard', icon: HomeIcon, current: false },
  { name: 'Exams', link: '/portal/exams', icon: BookOpenIcon, current: false },
  { name: 'Payments', link: '/portal/payment-details', icon: CurrencyDollarIcon, current: false },
]

const adminNavigation = [
  { name: 'Admin', link: '/portal/admin', icon: ViewGridIcon, current: false },

]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Portal() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  //Context
  const { auth } = useAuth();

  //Exam Mode logic
  const { examMode } = useExamMode()

  //Helper functions
  const logout = async () => {
    await axiosPrivate.get('/logout')
    navigate('/login')
  }

  return (
    <>
      <div className="h-full flex flex-1">
        {/* Narrow sidebar */}
        <div className="hidden w-28 bg-purple-800 overflow-y-auto md:block">
          <div className="w-full py-6 flex flex-col items-center">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="h-16 w-auto"
                src={white_logo}
                alt="PharmXellence Logo"
              />
            </div>
            <div className="flex-1 mt-6 w-full px-2 space-y-1">
              {sidebarNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.link}
                  className={({ isActive }) => isActive && !examMode ?
                    "bg-purple-700 text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium" :
                    examMode ? 'pointer-events-none text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium' : "text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  }
                  aria-current={item.current ? 'page' : undefined}
                  disabled={true}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-white' : 'text-purple-300 group-hover:text-white',
                      examMode ? 'text-gray-400' : '',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className={classNames(
                    examMode ? 'text-gray-400' : '',
                    'mt-2'
                  )}>{item.name}</span>
                </NavLink>
              ))}
              {auth?.roles?.includes(1986) && adminNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.link}
                  className={({ isActive }) => isActive && !examMode ?
                    "bg-purple-700 text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium" :
                    examMode ? 'pointer-events-none text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium' : "text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  }
                  aria-current={item.current ? 'page' : undefined}
                  disabled={true}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-white' : 'text-purple-300 group-hover:text-white',
                      examMode ? 'text-gray-400' : '',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className={classNames(
                    examMode ? 'text-gray-400' : '',
                    'mt-2'
                  )}>{item.name}</span>
                </NavLink>
              ))}


            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative max-w-xs w-full bg-purple-700 pt-5 pb-4 flex-1 flex flex-col">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        <span className="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 px-4 flex items-center justify-center">
                    <img
                      className="h-12 w-auto"
                      src={white_logo}
                      alt="PharmXellence Logo"
                    />
                  </div>
                  <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                    <nav className="h-full flex flex-col">
                      <div className="space-y-1">
                        {sidebarNavigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.link}
                            className={({ isActive }) => isActive ?
                              "bg-purple-800 text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium" :
                              "text-purple-100 hover:bg-purple-800 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? 'text-white' : 'text-purple-300 group-hover:text-white',
                                'mr-3 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </NavLink>
                        ))}
                        {auth?.roles?.includes(1986) && adminNavigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.link}
                            className={({ isActive }) => isActive && !examMode ?
                              "bg-purple-700 text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium" :
                              examMode ? 'pointer-events-none text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium' : "text-purple-100 hover:bg-purple-700 hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                            }
                            aria-current={item.current ? 'page' : undefined}
                            disabled={true}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? 'text-white' : 'text-purple-300 group-hover:text-white',
                                examMode ? 'text-gray-400' : '',
                                'h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            <span className={classNames(
                              examMode ? 'text-gray-400' : '',
                              'mt-2'
                            )}>{item.name}</span>
                          </NavLink>
                        ))}
                      </div>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="w-full">
            <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 sm:px-6">
                <div className="flex-1 flex">
                  <div className='flex flex-col justify-center space-y-1'>
                    <h1 className='text-xl font-semibold'>
                      <span>Pharm</span>
                      <span className='text-purple-800'>Xellence</span>
                    </h1>
                    <div className='bg-purple-100 w-max'>
                      <h6 className='text-sm font-bold'>Since 2013</h6>
                    </div>
                  </div>
                </div>
                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        <span className="sr-only">Open user menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-purple-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item key="profile">
                          {({ active }) => (
                            <Link
                              to="/portal/profile"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-center'
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item key="logout">
                          {({ active }) => (
                            <button
                              onClick={logout}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full'
                              )}
                            >
                              logout
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>
          <Outlet />
        </div>
      </div>
    </>
  )
}
