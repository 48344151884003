import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useLocalStorageValue } from '@react-hookz/web';
import QuestionPicker from './Helpers/QuestionPicker'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useExam from '../hooks/useExam'
import useAuth from '../hooks/useAuth'
import useExamMode from '../hooks/useExamMode'
import Modal from './Helpers/Modal';
import Timer from './Helpers/Timer';
import ErrorAlert from './Helpers/ErrorAlert';


function Question() {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  let { exam, setExam } = useExam()
  const { auth } = useAuth()
  const { setExamMode } = useExamMode()
  const [answersArray, setAnswersArray] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [obtainedMarks, setObtainedMarks] = useState(0)
  const [success, setSuccess] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [value, setValue, removeValue, fetchValue] = useLocalStorageValue('answers', answersArray);
  const [timerValue, setTimerValue, removeTimerValue] = useLocalStorageValue('timer', timeRemaining);
  const [isError, setIsError] = useState(false)
  const [isFetchingExamError, setIsFetchingExamError] = useState(false)

  // HELPER FUNCTIONS
  const getExamById = async () => {
    try {
      const response = await axiosPrivate.get(`/exams/${params.examId}`)
      setIsFetchingExamError(false)
      setExam(response.data.exam)
      exam = response.data.exam
      return response
    } catch (error) {
      console.error("Error while fetching the exam by Id", error)
      setIsFetchingExamError(true)
      setExamMode(false)
    }
  }

  const prepareAnswersArray = (exam) => {
    if (exam) {
      const questions = exam.questionPaperId.questions
      const result = [];
      for (const question of questions) {
        result.push({
          questionId: question._id,
          answer: null
        })
      }
      return result
    }
  }

  const questions = (exam && exam.questionPaperId.questions) || []

  const handleRadioChange = (e) => {
    const copyAnswersArr = [...answersArray]
    copyAnswersArr[params.questionId - 1].answer = e.target.value
    setAnswersArray(copyAnswersArr)
    setValue(copyAnswersArr)
  }

  const goToPrevious = () => {
    const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
    navigate(`${futureLocationPath}/${params.questionId - 1}`)
  }

  const goToNext = () => {
    const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
    navigate(`${futureLocationPath}/${Number(params.questionId) + 1}`)
  }

  const calculateMarks = (questionsArray, answersArray) => {
    let count = 0;
    for (let index = 0; index < questionsArray.length; index++) {
      const questionArrayItem = questionsArray[index];
      const answerArrayItem = answersArray[index];
      if (questionArrayItem.answer == answerArrayItem.answer) {
        count++;
      }
    }
    return count
  }

  const submitExam = async () => {
    try {
      setIsError(false)
      setExamMode(false)
      const obtainedMarks = calculateMarks(questions, answersArray)
      setObtainedMarks(obtainedMarks)
      const resultPayload = {
        // questions: questions,
        examId: exam._id,
        courseId: exam.courseId,
        questionBankId: exam.questionPaperId._id,
        userId: auth._id,
        date: new Date(),
        maxMarks: questions.length,
        obtainedMarks: obtainedMarks,
        answers: answersArray
      }
      setShowModal(true)
      const response = await axiosPrivate.post('/results', resultPayload)
      if (response.status === 200) {
        setSuccess(true)
        removeTimerValue()
        removeValue()
      }
    } catch (err) {
      console.log("Error while submitting the result", err)
      setExamMode(false)
      setIsError(true);
      setShowModal(false)
      removeTimerValue()
    }
  }

  useEffect(() => {
    if (questions?.length) {
      const answersArray = questions.map(question => {
        return {
          questionId: question._id,
          answer: null
        }
      })
      if (!value?.length) {
        setAnswersArray(answersArray)
      }
    }
  }, [questions])

  useEffect(() => {
    if (!exam) {
      getExamById()
    }
    //Set exam Mode
    setExamMode(true)

    //Timer
    if (timerValue) {
      setTimeRemaining(timerValue)
    } else {
      const currTime = new Date()
      const twoHours = currTime.setSeconds(currTime.getSeconds() + 7200);
      setTimeRemaining(twoHours)
      setTimerValue(twoHours)
    }
  }, [])

  useEffect(() => {
    const preparedAnswersArray = prepareAnswersArray(exam)
    if ((answersArray && answersArray.length <= 0)) {
      setAnswersArray(preparedAnswersArray)
    }
    if (value && value?.length) {
      const answerArrFromLS = value
      setAnswersArray(answerArrFromLS)
    }
  }, [exam])

  return (
    <>
      <div className='min-h-screen flex flex-col flex-1 p-2 overflow-hidden lg:flex-row'>
        {isFetchingExamError && <div className='flex-1'>
          <ErrorAlert message="There was some error loading your exam questions." />
        </div>}
        {(exam && answersArray && answersArray.length > 0) ?
          <>
            <main className='p-2 lg:w-2/3'>
              {isError && <ErrorAlert message="There was some error occurred while submitting the result. Please try again." />}
              <Timer expiryTimestamp={timeRemaining} submitExam={submitExam} errorNotification={isError} />
              <div className='mt-4 min-h-[300px]'>
                <span className='flex'>
                  <h1 className='font-bold mr-2'>Q:{params.questionId}</h1>
                  <h1 dangerouslySetInnerHTML={{ __html: questions[params.questionId - 1].question }}></h1>
                </span>
                <ul>
                  <li>
                    <fieldset className="mt-4">
                      <div className="space-y-4">
                        <div key="optionA" className="flex items-center">
                          <input
                            id="optionA"
                            name="option"
                            type="radio"
                            className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                            value={questions[params.questionId - 1].optionA}
                            onChange={handleRadioChange}
                            checked={answersArray[params.questionId - 1].answer === questions[params.questionId - 1].optionA}
                          />
                          <label htmlFor="optionA" className="ml-3 block text-sm font-medium text-gray-700">
                            {questions[params.questionId - 1].optionA}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  <li>
                    <fieldset className="mt-4">
                      <div className="space-y-4">
                        <div key="optionB" className="flex items-center">
                          <input
                            id="optionB"
                            name="option"
                            type="radio"
                            className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                            value={questions[params.questionId - 1].optionB}
                            onChange={handleRadioChange}
                            checked={answersArray[params.questionId - 1].answer === questions[params.questionId - 1].optionB}
                          />
                          <label htmlFor="optionB" className="ml-3 block text-sm font-medium text-gray-700">
                            {questions[params.questionId - 1].optionB}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  <li>
                    <fieldset className="mt-4">
                      <div className="space-y-4">
                        <div key="optionC" className="flex items-center">
                          <input
                            id="optionC"
                            name="option"
                            type="radio"
                            className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                            value={questions[params.questionId - 1].optionC}
                            onChange={handleRadioChange}
                            checked={answersArray[params.questionId - 1].answer === questions[params.questionId - 1].optionC}
                          />
                          <label htmlFor="optionC" className="ml-3 block text-sm font-medium text-gray-700">
                            {questions[params.questionId - 1].optionC}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  <li>
                    <fieldset className="mt-4">
                      <div className="space-y-4">
                        <div key="optionD" className="flex items-center">
                          <input
                            id="optionD"
                            name="option"
                            type="radio"
                            className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                            value={questions[params.questionId - 1].optionD}
                            onChange={handleRadioChange}
                            checked={answersArray[params.questionId - 1].answer === questions[params.questionId - 1].optionD}
                          />
                          <label htmlFor="optionD" className="ml-3 block text-sm font-medium text-gray-700">
                            {questions[params.questionId - 1].optionD}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  {questions[params.questionId - 1]?.optionE && <li>
                    <fieldset className="mt-4">
                      <div className="space-y-4">
                        <div key="optionE" className="flex items-center">
                          <input
                            id="optionE"
                            name="option"
                            type="radio"
                            className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                            value={questions[params.questionId - 1].optionE}
                            onChange={handleRadioChange}
                            checked={answersArray[params.questionId - 1].answer === questions[params.questionId - 1].optionE}
                          />
                          <label htmlFor="optionE" className="ml-3 block text-sm font-medium text-gray-700">
                            {questions[params.questionId - 1].optionE}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </li>}
                </ul>
              </div>
              <div className='flex justify-between mt-10'>
                <div>
                  <button
                    type="button"
                    disabled={Number(params.questionId) === 1}
                    className="mr-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:text-purple-300 disabled:bg-purple-100 disabled:pointer-events-none"
                    onClick={goToPrevious}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    disabled={Number(params.questionId) === (questions.length)}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:text-purple-300 disabled:bg-purple-100 disabled:pointer-events-none"
                    onClick={goToNext}
                  >
                    Next
                  </button>
                </div>
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={submitExam}
                >
                  Submit
                </button>
              </div>
            </main>
            <aside className="mt-10 border-t overflow-y-auto border-gray-400 p-2 lg:w-1/3 lg:mt-0 lg:border-l">
              <QuestionPicker totalQuestions={questions} />
            </aside>
          </> : null}
      </div>
      {showModal && <Modal success={success} obtainedMarks={obtainedMarks} />}
    </>
  )
}

export default Question