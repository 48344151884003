function Separator({ name }) {
  return (
    <div className="relative my-4">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <span className="pr-2 bg-white text-xl font-semibold text-purple-600">{name}</span>
      </div>
    </div>
  )
}

export default Separator