import useAuth from '../hooks/useAuth'
import Heading from './Helpers/Heading'
import CourseEnrolmentTable from './Helpers/CourseEnrolmentTable'
import Separator from './Helpers/Separator';
import ResultsTable from './Helpers/ResultsTable';
import MarketingCourseTable from './Helpers/MarketingCourseTable';

function Dashboard() {

  //Context
  const { auth } = useAuth();
  return (
    <>
      <div className="flex-1 flex items-stretch overflow-hidden bg-gray-50 flex-col lg:flex-row" >
        <main className="flex-1 overflow-y-auto">
          {/* Primary column */}
          <section aria-labelledby="primary-heading" className="min-w-0 flex-1 h-full flex flex-col lg:order-last p-8">
            <Heading heading="Dashboard" />
            <h6 className='mt-2'>Welcome {auth.name}!</h6>
            {/* Your content */}
            <article>
              <Separator name="Courses" />
              <CourseEnrolmentTable />
            </article>
            <article>
              <div className='mt-10'>
                <Separator name="Results" />
                <ResultsTable />
              </div>
            </article>
          </section>
        </main>

        {/* Secondary column */}
        <aside className="py-8 px-4 border-l border-gray-200 overflow-y-auto max-h-[1500px] lg:w-[25%]">
          {/* Your content */}
          <Heading heading="Available courses" />
          <MarketingCourseTable />
        </aside>
      </div>
    </>
  )
}

export default Dashboard