import { useQuery } from 'react-query'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import ErrorAlert from './Helpers/ErrorAlert'
import ExamsEnrolmentTable from './Helpers/ExamsEnrolmenttable'
import Heading from './Helpers/Heading'
import Loader from './Helpers/Loader'

function Exams() {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth()
  const isOSCE = auth?.courseEnrolled?.some(course => course.coursetype === "Qualifying Exam Part - II (OSCE)"
  )
  const EESequenceMapper = [
    {
      name: "Biomedical Sciences",
      sequence: 1
    },
    {
      name: "Pharmaceutical Sciences",
      sequence: 2
    },
    {
      name: "Social Behavioral and Administrative Sciences",
      sequence: 3
    },
    {
      name: "Pharmacy Practice",
      sequence: 4
    },
    {
      name: "EE- Calculations",
      sequence: 5
    },
    {
      name: "Full Course - 1",
      sequence: 6
    },
    {
      name: "Full Course - 2",
      sequence: 7
    },
    {
      name: "Full Course - 3",
      sequence: 8
    },
  ]

  const MCQSequenceMapper = [
    {
      name: "Calculations",
      sequence: 1
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 1",
      sequence: 2
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 2",
      sequence: 3
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 3",
      sequence: 4
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 4",
      sequence: 5
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 5 ",
      sequence: 6
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 6",
      sequence: 7
    },
    {
      name: "Qualifying Exam - Part - I (MCQ): Exam - 7",
      sequence: 8
    },
    {
      name: "Full Exam - Exam - 9",
      sequence: 9
    },
    {
      name: "Final Exam - 1",
      sequence: 10
    },
  ]
  const sequenceExams = (data) => {
    //Sequence the data
    const EE_Exams_Raw = data.EE
    const sequencedEE = EE_Exams_Raw.reduce((prev, curr) => {
      const indexToAdd = EESequenceMapper.findIndex(el => el.name === curr.examName)
      if (indexToAdd > -1) {
        prev[indexToAdd - 1] = curr
      } else {
        prev.push(curr)
      }
      return prev
    }, [])

    const MCQ_Exams_Raw = data.MCQ
    const sequencedMCQ = MCQ_Exams_Raw.reduce((prev, curr) => {
      //Remove Full Exam - Exam - 9 and Final Exam - 1 from the list
      if (curr.examName === "Full Exam - Exam - 9" || curr.examName === "Final Exam - 1") {
        return prev
      }
      const indexToAdd = MCQSequenceMapper.findIndex(el => el.name === curr.examName)
      if (indexToAdd > -1) {
        prev[indexToAdd] = curr
      } else {
        prev.push(curr)
      }
      return prev
    }, [])
    return {
      EE: sequencedEE,
      MCQ: sequencedMCQ,
    }
  }

  //It should fetch all exams - RQ stuff
  const getExamsByUserId = async () => {
    try {
      const userId = auth._id
      const response = await axiosPrivate.get(`/courses/user/${userId}`)
      const sequencedExams = sequenceExams(response.data.examsByUserId[0])
      return sequencedExams
    } catch (error) {
      console.error('Error while fetching exams', error)
      throw new Error('There was an error while fetching exams')
    }
  }
  const { data, isLoading, isError, isFetching } = useQuery('exams', getExamsByUserId)

  return (
    <>
      <div className='p-8 min-h-screen'>
        <Heading heading="Exams" />
        {isLoading && <Loader message="Loading" />}
        {isError && <ErrorAlert message="There was some error loading exams" />}
        {(data?.EE.length > 0 || data?.MCQ.length > 0) && <ExamsEnrolmentTable examDetails={data} />}
        {!isFetching && !isError && isOSCE && (<div className='mt-4'>
          <h1 className='font-medium text-purple-500 text-xl mt-6'>OSCE</h1>
          <p>For OSCE exam, please reach to the exam location on date and time specified.</p>
        </div>)}
        {!isFetching && !isError && !data?.EE.length && !data?.MCQ.length && !isOSCE && <div>No exams available to display. Please enroll into the courses.</div>}
      </div>
    </>
  )
}

export default Exams