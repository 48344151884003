import { Link } from 'react-router-dom'

export default function ExamsEnrolmentTable({ examDetails }) {

  return (
    <>
      <div className="sm:col-span-2">
        {Object.keys(examDetails).map(key => (
          examDetails[key].length > 0 && (<span key={key}>
            <h1 className='font-medium text-purple-500 text-xl mt-6'>{key}</h1>
            <dd className="mt-1 text-sm text-gray-900">
              <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {[...new Map(examDetails[key]
                  .map(v => [v.examName, v])).values()]
                  .map(exam => (
                    <Link
                      key={exam._id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      to={`/portal/exams/${exam._id}`}
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 w-0 truncate">{exam.examName}</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <p className="font-medium text-purple-600 hover:text-purple-500">
                          Start exam
                        </p>
                      </div>
                    </Link>
                  ))}
              </ul>
            </dd>
          </span>)
        )
        )}
      </div>
    </>
  )
}