import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import ErrorAlert from './ErrorAlert'
import { useState } from 'react'

function MarketingCourseTable() {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [isStripeError, setIsStripeError] = useState(false)

  //RQ stuff
  const getAllCourses = async () => {
    try {
      const response = await axiosPrivate.get('/courses')
      return response
    } catch (err) {
      console.error(err)
      throw new Error('Error while fetching all the courses')
    }
  }
  const { data, isError, isLoading } = useQuery('all-courses', getAllCourses)
  const extractedData = data?.data?.data || []
  let courseDetailsNew = null
  if (extractedData.length) {
    courseDetailsNew = extractedData.reduce((prevValue, course) => {
      //Check the course should not be from the already bought one
      const userCoursesEnrolled = auth.courseEnrolled.map(course => course._id)
      if (!userCoursesEnrolled.includes(course._id)) {
        prevValue[course.coursetype].courses.push({ name: course.coursename, price: course.price, _id: course._id, description: course?.description, price_id: course?.price_id, coursetype: course.coursetype })
      }
      return prevValue
    }, {
      'Evaluating Exam': {
        courses: []
      },
      'Qualifying Exam Part - I (MCQ)': {
        courses: []
      },
      'Qualifying Exam Part - II (OSCE)': {
        courses: []
      },
      'TEST course': {
        courses: []
      }

    })
  }
  let courseHeadings = []
  if (courseDetailsNew) {
    //Generate headings
    // courseHeadings = Object.keys(courseDetailsNew).filter((item) => item !== 'Evaluating Exam')
    courseHeadings = Object.keys(courseDetailsNew)
  }

  const handleBuyCourse = async (name, price_id, type) => {
    try {
      setIsStripeError(false)
      // const price_id = 'price_1KsImUGpSTch9r8UA9bFtc8E' //demo course id (OSCE day 5 & 6)
      const formattedName = `${type} - ${name}`
      const response = await axiosPrivate.post('/stripe/create-checkout-session', { name: formattedName, price_id, email: auth.emailAddress, address: auth.address, customerName: auth.name })
      if (response.status === 200) {
        window.location = response.data.url
      }
    } catch (err) {
      console.error("Error from handle buy course ->", err)
      setIsStripeError(true)
    }
  }

  return (
    <>
      {isLoading && <div>Loading courses...</div>}
      {isError && <ErrorAlert message="There was some error loading available courses" />}
      {isStripeError && <ErrorAlert message="There was some error on the payment system. Please try again." />}
      {courseHeadings.map(heading => (
        <span key={heading}>
          {courseDetailsNew[heading].courses.length > 0 && <h1 className='font-medium text-purple-500 text-xl mt-6'>{heading}</h1>}
          <div className="space-y-4 mt-2">
            {courseDetailsNew[heading].courses.map((course) => (
              <div key={course._id} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-700">{course.name}</h2>
                  <p className="text-gray-500">{course.description}</p>
                  <p className="mt-8">
                    <span className="text-2xl font-medium text-gray-700">${course.price}</span>
                  </p>
                  <button
                    className="mt-8 block w-full border border-purple-500 rounded-md py-2 text-sm font-semibold text-purple-800 text-center hover:bg-purple-700 hover:text-white"
                    onClick={() => handleBuyCourse(course.name, course.price_id, course.coursetype)}
                  >
                    Buy
                  </button>
                </div>
                {/* <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-700 tracking-wide uppercase">What's included</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            ))}
          </div>
        </span>
      ))}

    </>
  )
}

export default MarketingCourseTable