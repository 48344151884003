import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from './context/AuthProvider';
import { ExamProvider } from './context/ExamProvider';
import { ExamModeProvider } from './context/ExamModeProvider';
const queryClient = new QueryClient()
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ExamProvider>
            <ExamModeProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </ExamModeProvider>
          </ExamProvider>
        </AuthProvider>
      </BrowserRouter>
      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);