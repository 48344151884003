import { Outlet, useLocation } from "react-router-dom"
import Login from "./Login"

const Layout = () => {
  const location = useLocation()

  return (
    <main className="flex flex-1 bg-purple-50 min-h-screen">
      {(location?.pathname === '/') && <Login />}
      <Outlet />
    </main>
  )
}

export default Layout