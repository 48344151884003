import { ExclamationIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

function PaymentError() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <ExclamationIcon className="h-10 w-10 text-yellow-400" aria-hidden="true" />
      <h2>It seems that back button was pressed from payment or some error occurred.</h2>
      <h4>If you have tried the payment and didn't go well, please contact us at sushant.yt@gmail.com (+1-587-938-8816) for further assistance.</h4>
      <div className='flex items-center mt-4 self-center'>
        <Link to='/portal/dashboard' className="px-4 py-2 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Go to Dashboard</Link>
      </div>
    </div>
  )
}

export default PaymentError