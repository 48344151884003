import { createContext, useState } from "react";

const ExamContext = createContext({})

export const ExamProvider = ({ children }) => {
  const [exam, setExam] = useState(null)

  return (
    <ExamContext.Provider value={{ exam, setExam }}>
      {children}
    </ExamContext.Provider>
  )
}

export default ExamContext