

import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

function Timer({ expiryTimestamp, submitExam, errorNotification }) {
  const {
    seconds,
    minutes,
    hours,
    pause,
  } = useTimer({ expiryTimestamp, onExpire: () => submitExam() });

  useEffect(() => {
    if (errorNotification) {
      pause()
    }
  }, [errorNotification])

  return (
    <>
      {expiryTimestamp && <>
        <div className='font-semibold text-lg'>
          Timer: <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
        </div>
      </>}
    </>

  );
}

export default Timer