import { InformationCircleIcon } from '@heroicons/react/solid'

function Loader({ message }) {
  return (
    <div className="rounded-md bg-blue-100 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-blue-800">{message}</p>
        </div>
        <div className='h-8 w-8 ml-4'>
          <svg width="30" height="30" viewBox="0 0 110 110" xmlns="http://www.w3.org/2000/svg" fill="#1E40AF">
            <circle cx="12.5" cy="12.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="0s" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
              <animate attributeName="fillOpacity"
                begin="100ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="52.5" cy="12.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="300ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="52.5" cy="52.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="600ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="92.5" cy="12.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="800ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="92.5" cy="52.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="400ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="12.5" cy="92.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="700ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="52.5" cy="92.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="500ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
            <circle cx="92.5" cy="92.5" r="12.5">
              <animate attributeName="fillOpacity"
                begin="200ms" dur="1s"
                values="1;.2;1" calcMode="linear"
                repeatCount="indefinite" />
            </circle>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Loader