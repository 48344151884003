import { useState } from "react"
import { Link, NavLink, useLocation, useParams } from "react-router-dom"

export default function QuestionPicker({ totalQuestions }) {
  const [counter, setCounter] = useState(null)
  const location = useLocation()
  const params = useParams()
  const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
  const markQuestion = (index) => {
    totalQuestions[index].marked = !totalQuestions[index].marked
    setCounter(counter + 1)
  }
  return (
    <div className="w-full">
      <h2 className="text-md font-semibold my-4">QuestionPicker</h2>
      <div className="grid grid-cols-4 gap-2 place-content-center">
        {totalQuestions.map((question, index) => {
          return (
            <div key={index} className="border-2 border-gray-300 text-center flex flex-col p-1">
              <div className={`h-4 w-4  ml-auto rounded-full ${question?.marked ? `bg-red-300` : `bg-gray-200`}`} onClick={() => markQuestion(index)}></div>
              <NavLink className={({ isActive }) => isActive ? "text-cyan-600 bg-cyan-50 font-bold flex-1 pb-4" : "flex-1 pb-4"} to={`${futureLocationPath}/${index + 1}`} >
                {index + 1}
              </NavLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}
