import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from '../api/axios';
import { Link, useParams } from 'react-router-dom';
import logo from '../Logo/logopurple.svg';
import { useMutation } from "react-query";
import ErrorAlert from "./Helpers/ErrorAlert";
import SuccessAlert from "./Helpers/SuccessAlert";

export default function ResetPassword() {
  const [errMsg, setErrMsg] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const { register, handleSubmit } = useForm();
  const params = useParams()

  const resetPasswordMutateFn = async (payload) => {
    return await axios.post('/reset_password', payload)
  }
  const mutation = useMutation(resetPasswordMutateFn, {
    onError: (error) => {
      setGenericError(error.response.data.message)
    },
  })

  const onSubmit = async (data) => {
    try {
      //Reset everything
      setPasswordErrorMsg(false)
      setErrMsg(false)
      setGenericError(false)
      mutation.reset()
      const { email, password, cpassword } = data
      if (cpassword !== password) {
        //Match error
        setPasswordErrorMsg(true)
        return
      }
      if (!params.magicId || !password || !email) {
        //Generic error
        setErrMsg(true)
        return
      }

      const payload = {
        password: password,
        email: email,
        magicPass: params.magicId
      }
      mutation.mutate(payload)
    } catch (err) {
      setErrMsg(true) //Empty form submit so we tell users to fill up the form
    }
  }

  return (
    <>
      <div className="min-h-full bg-purple-100 w-96 flex flex-col justify-center m-auto py-4 sm:px-4 lg:px-4">
        <div className="h-32 w-32 mx-auto  overflow-hidden">
          <img src={logo} alt="PharmXellence Logo" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
        <div className="mt-8">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register("email")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    {...register("password")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="cpassword" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    id="cpassword"
                    name="cpassword"
                    type="password"
                    autoComplete="current-cpassword"
                    {...register("cpassword")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-800 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  {mutation.isLoading ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> : 'Reset'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-4">
          {errMsg && <ErrorAlert message="Please fill up the form and try again" />}
          {passwordErrorMsg && <ErrorAlert message="Passwords do not match" />}
          {genericError && <ErrorAlert message={genericError} />}
          {mutation.isSuccess && <SuccessAlert message="Password successfully reset. Please login to continue." />}
        </div>
        {mutation.isSuccess && <div className="mt-4">
          <Link to="/"
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-purple-800 bg-white hover:bg-purple-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Navigate to Portal
          </Link>
        </div>}
      </div>
    </>
  )
}
