
import { ChevronRightIcon } from '@heroicons/react/solid'
import useAuth from '../../hooks/useAuth'
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export default function CourseEnrolmentTable() {
  //Context
  const { auth } = useAuth();
  let courses = []
  if (auth?.courseEnrolled && auth?.courseEnrolled.length > 0) {
    courses = auth?.courseEnrolled.map(course => {
      return {
        _id: course._id,
        type: course.coursetype,
        name: course.coursename,
        amount: course.price,
        date: format(new Date(course.created_at), 'dd-MMM-yyyy'),
        currency: 'CAD',
      }
    })
  }
  return (
    <>
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {courses.length > 0 && courses.map((course) => (
            <li key={course._id}>
              <p className="block px-4 py-4 bg-white hover:bg-gray-50">
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{course.type} : {course.name}</span>
                      <span>
                        <span className="text-gray-900 font-medium">{course.amount}</span>{' '}
                        {course.currency}
                      </span>
                    </span>
                  </span>
                  <Link to="/portal/exams">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Link>
                </span>
              </p>
            </li>
          ))}
          {!courses.length && <div className='my-4 p-2'>You are not enrolled in any courses!</div>}
        </ul>
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block mt-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              {courses.length > 0 && <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Course Enrolled
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {courses.length > 0 && courses.map((course, index) => (
                    <tr key={index} className="bg-white">
                      <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex">
                          <Link to="/portal/exams" className="group inline-flex space-x-2 truncate text-sm">
                            <p className="text-gray-500 truncate group-hover:text-gray-900">
                              {course.type} : {course.name}
                            </p>
                          </Link>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span className="text-gray-900 font-medium">{course.amount} </span>
                        {course.currency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>}
              {!courses.length && <div className='my-4 p-2'>You are not enrolled in any courses!</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}