import { createContext, useState } from "react";

const ExamModeContext = createContext({})

export const ExamModeProvider = ({ children }) => {
  const [examMode, setExamMode] = useState(false)

  return (
    <ExamModeContext.Provider value={{ examMode, setExamMode }}>
      {children}
    </ExamModeContext.Provider>
  )
}

export default ExamModeContext;