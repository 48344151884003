import { useEffect } from 'react';
import { useQuery } from 'react-query'
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useExam from '../hooks/useExam';
import ErrorAlert from './Helpers/ErrorAlert';
import Heading from './Helpers/Heading';
import Loader from './Helpers/Loader';

function Exam() {
  let params = useParams();
  const axiosPrivate = useAxiosPrivate()
  const { exam, setExam } = useExam()
  const navigate = useNavigate();
  const location = useLocation();

  const getExamById = async () => {
    try {
      setExam(null)
      const response = await axiosPrivate.get(`/exams/${params.examId}`)
      return response
    } catch (error) {
      console.error("Error while fetching the exam by id 🛑", error)
      throw new Error('Error while fetching the exam by id')
    }
  }

  const initiateExam = () => {
    localStorage.removeItem('answers')
    navigate(`${location.pathname}/1`)
  }
  const { data, isLoading, isError } = useQuery('examById', getExamById)
  useEffect(() => {
    getExamById()
  }, [])

  return (
    <>
      <div className='flex flex-1 flex-col p-8 min-h-screen'>
        {isLoading && <Loader message="Loading exam" />}
        {isError && <ErrorAlert message="There was some error loading your exam" />}
        {data?.data?.exam && (
          <>
            <Heading heading={`Exam: ${data?.data?.exam.examName}`} />
            <div className='mt-4'>{data?.data?.exam.description}</div>
            <button
              onClick={initiateExam}
              className="px-3 py-2 mt-4 border border-transparent text-sm leading-4 font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 w-fit"
            >Continue</button>
          </>
        )}
        <Outlet />
      </div>
    </>
  )
}

export default Exam;