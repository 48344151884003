import { NavLink, useLocation, } from "react-router-dom"

export default function AnswerPicker({ totalAnswers }) {
  const location = useLocation()
  const futureLocationPath = location.pathname.split('/').slice(0, -1).join('/')
  return (
    <div className="w-full">
      <h2 className="text-md font-semibold my-4">Answers</h2>
      <div className="grid grid-cols-4 gap-2 place-content-center">
        {totalAnswers.map((answer, index) => {
          return (
            <div key={index} className="border-2 border-gray-300 text-center flex flex-col p-1">
              <div className={`h-4 w-4  ml-auto rounded-full pointer-events-none ${answer?.marked ? `bg-red-300` : `bg-gray-200`}`}></div>
              <NavLink className={answer.answer === answer.questionId.answer ? 'bg-green-300' : answer.answer === null ? 'bg-gray-300' : answer.answer !== answer.questionId.answer ? 'bg-red-300' : ''} to={`${futureLocationPath}/${index + 1}`} >
                {index + 1}
              </NavLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}
