import { Route, Routes } from 'react-router-dom';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import Exam from './components/Exam';
import Exams from './components/Exams';
import ForgotPassword from './components/ForgotPassword';
import Layout from './components/Layout';
import Login from './components/Login';
import NotFound from './components/NotFound';
import PaymentError from './components/PaymentError';
import PaymentSuccess from './components/PaymentSuccess';
import PersistLogin from './components/PersisLogin';
import Portal from './components/Portal';
import Profile from './components/Profile';
import ProfileData from './components/ProfileData';
import Question from './components/Question';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import ReviewExam from './components/ReviewExam';

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:magicId" element={<ResetPassword />} />

        {/* PROTECTED ROUTES */}
        {/* <Route element={<PersistLogin />}> */}
        <Route path="portal" element={<Portal />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="exams" element={<Exams />} />
          <Route path="exams/:examId" element={<Exam />} />
          <Route path="exams/:examId/:questionId" element={<Question />} />
          <Route path="results/:resultId/:questionId" element={<ReviewExam />} />
          <Route path="payment-details" element={<ProfileData />} />
          <Route path="payment/success" element={<PaymentSuccess />} />
          <Route path="payment/error" element={<PaymentError />} />
          <Route path="admin" element={<Admin />} />
        </Route>
        {/* </Route> */}
      </Route>
    </Routes>
  )
}
export default App;